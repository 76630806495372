// extracted by mini-css-extract-plugin
export var automobile = "index-module--automobile--SYjVJ";
export var bank = "index-module--bank--QJxvw";
export var clickArea = "index-module--clickArea--NWe+u";
export var content = "index-module--content--Bx19D";
export var desc = "index-module--desc--OVLoO";
export var descBg = "index-module--descBg--vfOIF";
export var descContent = "index-module--descContent--g6pFq";
export var detail_bottom = "index-module--detail_bottom--LMVaF";
export var detail_text = "index-module--detail_text--scEoA";
export var detail_title = "index-module--detail_title--2OGGG";
export var education = "index-module--education--XrgY3";
export var enterpriseService = "index-module--enterpriseService--bE0Ha";
export var entertainment = "index-module--entertainment--Rl-hg";
export var financialIntermediation = "index-module--financialIntermediation--vNGpj";
export var floatBottom = "index-module--floatBottom--PATcP";
export var floatLogoBottom = "index-module--floatLogoBottom--JsjKs";
export var floatLogoContainer = "index-module--floatLogoContainer--CJNfk";
export var floatLogoText1 = "index-module--floatLogoText1--IsVgK";
export var floatLogoText2 = "index-module--floatLogoText2--mDsCp";
export var floatLogoTop = "index-module--floatLogoTop--VnQTv";
export var floatNoticeTool1 = "index-module--floatNoticeTool1--fTdV6";
export var floatNoticeTool2 = "index-module--floatNoticeTool2--Rg4L6";
export var floatTop = "index-module--floatTop--5b28g";
export var foldBtn = "index-module--foldBtn--GXO+S";
export var hoverShow = "index-module--hoverShow--733m8";
export var hoverUp = "index-module--hover-up--Rkwzf";
export var insurance = "index-module--insurance--Xg6Er";
export var onlineFinance = "index-module--onlineFinance--Dtl7O";
export var onlineMedicalTreatment = "index-module--onlineMedicalTreatment--UlveD";
export var onlineRetailer = "index-module--onlineRetailer--s5N3W";
export var onlineShow = "index-module--onlineShow--3lgqm";
export var operator = "index-module--operator--Ezird";
export var overviewPage = "index-module--overviewPage--lE1nB";
export var position_insurance = "index-module--position_insurance--JiuYJ";
export var position_left = "index-module--position_left--rYeZO";
export var position_right = "index-module--position_right--LG0Lj";
export var property = "index-module--property--dwOJb";
export var security = "index-module--security--Z1iHi";
export var sensors_logo = "index-module--sensors_logo--GC+LP";
export var supermarket = "index-module--supermarket--LhI4k";
export var title = "index-module--title--OseKL";
export var todoItem = "index-module--todoItem--1rkXg";
export var todoList = "index-module--todoList--Dyf2x";