import React, { useEffect, useState, useCallback } from "react";
import Seo from 'components/seo';
import ScrollBarShowLayout from 'components/ScrollBarShowLayout';
import { useStaticQuery, graphql } from 'gatsby'
import videojs from "video.js";
import Layout from 'layouts/zh';
import { getInfo } from 'api/onlineDemo';
import 'video.js/dist/video-js.css'
import classnames from 'classnames';

import * as styles from './index.module.less';

const onContextMenuFun = (e: any) => {
    e.preventDefault()
}

const videoJsOptions = {
  autoplay: true,
  controls: false,
  loop: true,
  muted: true,
  sources: 'https://ow-file.sensorsdata.cn/www/democenter/overview.mp4'
}
interface taskStatusProps {
  conferenceRegistration?: Boolean;
  demoInterpretation?: Boolean;
  industryDemo?: Boolean;
  industryGoods?: Boolean;
  targetTool?: Boolean;
  userId?: String;
}

const DemoCenter = () => {
  const [user, setUser] = useState(null)
  const [float, setFloat] = useState(6);
  const [showFloatDetail, setShowFloatDetail] = useState(false);
  const [innerHeight, setInnerHeight] = useState(937)
  const [videoEl, setVideoEl] = useState(null)

  const [taskStatus, setTaskStatus] = useState<taskStatusProps>({});
 
  const { navigateYaml } = useStaticQuery(graphql`
    query {
      navigateYaml {
         title
         descriptions
         keywords
         saTitle
        overall_catalogo
        overall_catalogo_mb
        description {
          automobile
          bank
          education
          enterpriseService
          entertainment
          financialIntermediation
          insurance
          onlineFinance
          onlineMedicalTreatment
          onlineRetailer
          operator
          property
          security
          supermarket
          left_icon
          right_icon
        }
        defaultShow {
          logo_img
          text_one
        }
        clickShow {
          left {
            fold_icon
            fold_text
            param_one_content
            param_one_title
            param_two_content_1
            param_two_content_2
            param_two_title
          }
          right {
            active_icon
            desc
            icon
            title
            task_list {
              key
              text
            }
          }
        }
      }
    }
  `)
  const {title, saTitle, descriptions, keywords, overall_catalogo, overall_catalogo_mb, description, defaultShow, clickShow } = navigateYaml
  // video实例
  const videoRef = useCallback((node) => {
    setVideoEl(node)
  }, [])

   // 初始化video组件
  useEffect(() => {
    if (!videoEl) {
      return
    }
    // @ts-ignore
    videojs(videoEl, {
      ...videoJsOptions,
      poster: overall_catalogo
    })
  }, [videoEl])

  const handleGetInnerH = (H) => {
    setInnerHeight(H)
    const clientW = document.body.clientWidth;
    if (clientW >= 1920) {
      const float1 = (clientW - 1920) / 2 + 6;
      setFloat(float1)
    } else {
      setFloat(6)
    }
  }

  const goToDetail = (url) => {
    window.open(url)
  }

  useEffect(() => {
    getInfo().then(({data}: any) => {
      setTaskStatus({ ...data })
    }).catch(error => {
      console.log(error)
    })
  }, [])

  const renderFloatNoticeTool = () => {
    return (
      <>
        <div className={classnames(styles.floatNoticeTool1, innerHeight >= 937 ? styles.floatTop : styles.floatBottom)} style={{ right: `${float}px` }}>
          <p className={styles.title}>
            {clickShow?.right?.title}
          </p>
          <p className={styles.desc}> {clickShow?.right?.desc}</p>
              <dl className={styles.todoList}>
                {clickShow?.right.task_list?.map(item => (
                  <dt className={styles.todoItem} key={item.key}>
                    <img src={taskStatus?.[item.key] ? clickShow?.right?.active_icon : clickShow?.right?.icon} />
                    {item.text}
                </dt>
                ))}
          </dl>
        </div>
      
        <div className={classnames(styles.floatNoticeTool2,  innerHeight >= 937 ? styles.floatTop : styles.floatBottom)} style={{ right: `${float + 220}px` }}>
          <div className={styles.title}>
            {clickShow?.left?.param_one_title}
            <p className={styles.foldBtn} onClick={() => setShowFloatDetail(false)}>
              <img className="mr-[3px]" width="11px" src={clickShow?.left?.fold_icon} />
              <span>{clickShow?.left?.fold_text}</span>
            </p>
          </div>
          <p className={styles.desc}>
            {clickShow?.left?.param_one_content}
          </p>
          <p className={styles.detail_title}>
            {clickShow?.left?.param_two_title}
          </p>
          <p className={styles.detail_text}>
            {clickShow?.left?.param_two_content_1}
          </p>
          <p className={classnames(styles.detail_bottom, styles.detail_text)}>
            {clickShow?.left?.param_two_content_2}
          </p>
        </div>
    </>
    )
  }

  const renderFloatLogo = () => (
    <div className={classnames(styles.floatLogoContainer, innerHeight >= 937 ? styles.floatLogoTop :styles.floatLogoBottom)} style={{ right: `${float + 131}px` }}>
            <img src={defaultShow?.logo_img} className={styles.sensors_logo} />
            <p className={styles.floatLogoText1}>{defaultShow?.text_one}</p>
        </div>
  )
  const renderDemoCenter = (<div className={styles.overviewPage}>
      <video
        controlsList="nodownload"
        onContextMenu={onContextMenuFun}
        ref={videoRef}
        width="1920px"
        className="video-js vjs-big-play-centered"
        />
    {/*证券*/}
    <div className={classnames(styles.clickArea, styles.security)} onClick={() => goToDetail('/democenter/securities.html')}>
      <div className={classnames(styles.descContent)}>
        <div className={classnames(styles.descBg, styles.position_insurance, styles.hoverShow)} style={{ backgroundImage: `url(${description?.left_icon})` }}>
          <span className={styles.content}>
            {description?.security}
          </span>
        </div>
      </div>
    </div>
    {/*互联网金融*/}
    <div className={classnames(styles.clickArea, styles.onlineFinance)} onClick={() => goToDetail('/democenter/consumerfinance.html')}>
      <div className={classnames(styles.descContent)}>
          <div className={classnames(styles.descBg, styles.position_left, styles.hoverShow)} style={{ backgroundImage: `url(${description?.right_icon})` }}>
            <span className={styles.content}>
              {description?.onlineFinance}
            </span>
          </div>
      </div>    
    </div>
    {/*电商*/}
    <div className={classnames(styles.clickArea, styles.onlineRetailer)} onClick={() => goToDetail('/democenter/ecommerce.html')}>
      <div className={classnames(styles.descContent)}>
        <div className={classnames(styles.descBg, styles.position_right, styles.hoverShow)} style={{ backgroundImage: `url(${description?.left_icon})` }}>
          <span className={styles.content}>
            {description?.onlineRetailer}
          </span>
        </div>
      </div>    
    </div>
    {/*零售*/}
    <div className={classnames(styles.clickArea, styles.supermarket)} onClick={() => goToDetail('/democenter/retailing.html')}>
      <div className={classnames(styles.descContent)}>
        <div className={classnames(styles.descBg, styles.position_left, styles.hoverShow)} style={{ backgroundImage: `url(${description?.right_icon})` }}>
          <span className={styles.content}>
            {description?.supermarket}
          </span>
        </div>
      </div>      
    </div>
    {/*保险*/}
    <div className={classnames(styles.clickArea, styles.insurance)} onClick={() => goToDetail('/democenter/insurance.html')}>
      <div className={classnames(styles.descContent)}>
        <div className={classnames(styles.descBg, styles.position_right, styles.hoverShow)} style={{ backgroundImage: `url(${description?.left_icon})` }}>
          <span className={styles.content}>
            {description?.insurance}
          </span>
        </div>
      </div>
    </div>
    {/*银行*/}
    <div className={classnames(styles.clickArea, styles.bank)} onClick={() => goToDetail('/democenter/bank.html')}>
      <div className={classnames(styles.descContent)}>
        <div className={classnames(styles.descBg, styles.position_right, styles.hoverShow)} style={{ backgroundImage: `url(${description?.left_icon})` }}>
          <span className={styles.content}>
            {description?.bank}
          </span>
        </div>
      </div>
    </div>
    {/*运营商*/}
    <div className={classnames(styles.clickArea, styles.operator)} onClick={() => goToDetail('/democenter/telecommunication.html')}>
      <div className={classnames(styles.descContent)}>
        <div className={classnames(styles.descBg, styles.position_right, styles.hoverShow)} style={{ backgroundImage: `url(${description?.left_icon})` }}>
          <span className={styles.content}>
            {description?.operator}
          </span>
        </div>
      </div>    
    </div>
    {/*企服*/}
    <div className={classnames(styles.clickArea, styles.enterpriseService)} onClick={() => goToDetail('/democenter/enterpriseservice.html')}>
      <div className={classnames(styles.descContent)}>
        <div className={classnames(styles.descBg, styles.position_left, styles.hoverShow)} style={{ backgroundImage: `url(${description?.right_icon})` }}>
          <span className={styles.content}>
            {description?.enterpriseService}
          </span>
        </div>
      </div>   
    </div>
    {/*地产*/}
    <div className={classnames(styles.clickArea, styles.property)} onClick={() => goToDetail('/democenter/realestate.html')}>
      <div className={classnames(styles.descContent)}>
        <div className={classnames(styles.descBg, styles.position_right, styles.hoverShow)} style={{ backgroundImage: `url(${description?.left_icon})` }}>
          <span className={styles.content}>
            {description?.property}
          </span>
        </div>
      </div>
    </div>
    {/*在线医疗*/}
    <div className={classnames(styles.clickArea, styles.onlineMedicalTreatment)} onClick={() => goToDetail('/democenter/onlinehealthcare.html')}>
      <div className={classnames(styles.descContent)}>
        <div className={classnames(styles.descBg, styles.position_left, styles.hoverShow)} style={{ backgroundImage: `url(${description?.right_icon})` }}>
          <span className={styles.content}>
            {description?.onlineMedicalTreatment}
          </span>
        </div>
      </div>   
    </div>
    {/*文娱*/}
    <div className={classnames(styles.clickArea, styles.entertainment)} onClick={() => goToDetail('/democenter/entertainment.html')}>
      <div className={classnames(styles.descContent)}>
        <div className={classnames(styles.descBg, styles.position_left, styles.hoverShow)} style={{ backgroundImage: `url(${description?.right_icon})` }}>
          <span className={styles.content}>
            {description?.entertainment}
          </span>
        </div>
      </div>   
    </div>
    {/*教育*/}
    <div className={classnames(styles.clickArea, styles.education)} onClick={() => goToDetail('/democenter/education.html')}>
      <div className={classnames(styles.descContent)}>
        <div className={classnames(styles.descBg, styles.position_left, styles.hoverShow)} style={{ backgroundImage: `url(${description?.right_icon})` }}>
          <span className={styles.content}>
            {description?.education}
          </span>
        </div>
      </div>   
    </div>
    {/*汽车*/}
    <div className={classnames(styles.clickArea, styles.automobile)} onClick={() => goToDetail('/democenter/automobiles.html')}>
      <div className={classnames(styles.descContent)}>
        <div className={classnames(styles.descBg, styles.position_left, styles.hoverShow)} style={{ backgroundImage: `url(${description?.right_icon})` }}>
          <span className={styles.content}>
            {description?.automobile}
          </span>
        </div>
      </div>
    </div>
    {/*融媒*/}
    <div className={classnames(styles.clickArea, styles.financialIntermediation)} onClick={() => goToDetail('/democenter/media.html')}>
      <div className={classnames(styles.descContent)}>
        <div className={classnames(styles.descBg, styles.position_left, styles.hoverShow)} style={{ backgroundImage: `url(${description?.right_icon})` }}>
          <span className={styles.content}>
            {description?.financialIntermediation}
          </span>
        </div>
      </div>
      </div>
      {showFloatDetail && renderFloatNoticeTool()}
      {!showFloatDetail && renderFloatLogo()} 
  </div>)

  return (
    <Layout showFloat={false} showCookieAuth={false} hideFooter={true}>
      <Seo
        title={title}
        description={descriptions}
        keywords={keywords}
        saTitle={saTitle}
      />
      <div className={classnames('block lg:hidden')}>
        <img width='100%' src={overall_catalogo_mb} />
      </div>
      <ScrollBarShowLayout className='hidden lg:block' limitWidth={1920} innerH={937} getCurInnerH={handleGetInnerH} childrenEle={renderDemoCenter} getUser={(user) => setUser(user)}/>
    </Layout>
  )
}

export default DemoCenter;